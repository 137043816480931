import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import {
  injectIntl,
  intlShape
} from 'react-intl';
import loadable from '@loadable/component';
import * as R from 'ramda';
import classNames from 'classnames';
import Container from 'reactstrap/lib/Container';
import { App as AppCore } from 'core/app';
import { FormattedTag } from 'core/components';
import {
  withCoreComponent,
  withLocale,
  withUser,
  withModalActions,
  withNotificationsActions,
  withIsInsideReactNativeApp,
  withJackpotsActions,
  withJackpotsData
} from 'core/hocs';
import {
  getEnv,
  checkIsInsideReactNativeApp,
  pushMessageToReactNativeApp,
  isEmptyOrNil
} from 'core/helpers';
import { DEVICES_TYPES, LOYALTY_POINTS_STATUSES, NOTIFICATION_TYPES } from 'core/constants';
import { parse as parseQueryString } from 'qs';

import { withPwaInstall } from 'hocs/with-pwa-install';
import { clearLocalStorageData, getLocalStorageData, setLocalStorageData } from 'helpers/local-storage';
import { IconsDefs } from 'components/icons/icons-defs';
import { Header } from 'components/header/header';
import { SupportModal } from 'components/modals/support-modal/support-modal';
import { LinkErrorModal } from 'components/modals/link-error-modal/link-error-modal';
import { DepositModal } from 'components/modals/deposit-modal/deposit-modal';
import { NewLevelModal } from 'components/modals/new-level-modal/new-level-modal';
import { CompleteSignUpModal } from 'components/modals/complete-sign-up-modal/complete-sign-up-modal';
import { ResetPasswordModal } from 'components/modals/reset-password-modal/reset-password-modal';
import { GlobalNotificationsStack } from 'components/global-notifications-stack/global-notifications-stack';
import { Sidebar } from 'components/sidebar/sidebar';
import { Footer } from 'components/footer/footer';
import { CioTracker } from 'components/trackers/cio-tracker';
import { RecaptchaLoader } from 'components/recaptcha-loader/recaptcha-loader';
import { GlobalEventsHandler } from 'components/global-events-handler/global-events-handler';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { LocationTracker } from 'components/location-tracker/location-tracker';
import { ConfirmOtpModal } from 'components/modals/confirm-otp-modal/confirm-otp-modal';
import { KycNotificationModal } from 'components/modals/kyc-notification-modal/kyc-notification-modal';
import { SetupOtpModal } from 'components/modals/setup-otp-modal/setup-otp-modal';
import { RemoveOtpModal } from 'components/modals/remove-otp-modal/remove-otp-modal';
import { CookiesPolicy } from 'components/cookies-policy/cookies-policy';
import { MobileGameContainer } from 'components/mobile-game-container/mobile-game-container';
// import { PwaInstallGuide } from 'components/pwa-install-guide/pwa-install-guide';
import { SignInModal } from 'components/modals/sign-in-modal/sign-in-modal';
import { SignUpModal } from 'components/modals/sign-up-modal/sign-up-modal';
import { PromoInfoModal } from 'src/components/modals/promo-info-modal/promo-info-modal';
import { SocialAuthTracker } from 'components/social-auth-tracker/social-auth-tracker';
import { FreeSpinsNotifier } from 'components/free-spins-notifier/free-spins-notifier';
import { ConfirmPhoneModal } from 'components/modals/confirm-phone-modal/confirm-phone-modal';
import { SideModalBar } from 'components/side-modal-bar/side-modal-bar';
import { ProfileTabs } from 'components/profile-tabs/profile-tabs';
// import { LiveChatIncChat } from './components/live-chats/live-chat-inc-chat/live-chat-inc-chat';
import { GameWinnerCarouselMobile } from 'components/game-winner-list/game-winner-carousel-mobile';
import { BottomMobileActions } from 'components/bottom-mobile-actions/bottom-mobile-actions';
import { PaymentContainer } from './components/payment-container/payment-container';
import { ZendeskChat } from './components/live-chats/zendesk-chat/zendesk-chat';
import { parseJson } from './helpers/json';
import { GTM } from './helpers/ga';

import {
  PAGE_NAMES,
  // LIVECHAT_INC_GROUPS,
  MODAL_IDS,
  KYC_STATUS,
  GTM_EVENT_FIELDS,
} from './constants';

import './locales';

import './app.scss';

const {
  LOYALTY,
  GAME,
  TOURNAMENT,
  RESET_PASSWORD,
  HOME,
  WALLET,
  TOURNAMENTS,
  GAMES,
  PROVIDER,
  CATEGORY,
  PAYMENTS,
  BANKING_HISTORY,
  SPORTSBOOK,
  IN_PLAY,
  FAQ,
  PROFILE,
  CASHIER,
} = PAGE_NAMES;

const {
  USER_ID,
} = GTM_EVENT_FIELDS;

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));

const LANDING_PAGE_ROUTE = getEnv('PROMO_LANDING_PAGE_ROUTE');

export class AppUI extends Component {
  static propTypes = {
    renderRoutes: PropTypes.func.isRequired,
    route: PropTypes.shape({
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
          exact: PropTypes.bool,
        }),
      ),
    }).isRequired,
    is404: PropTypes.bool.isRequired,
    deviceType: PropTypes.string.isRequired,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
    isPwa: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    userData: PropTypes.shape().isRequired,
    userBalance: PropTypes.shape(),
    isUserRegistered: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    showNotifications: PropTypes.func.isRequired,
    isInsideReactNativeApp: PropTypes.bool.isRequired,
    setIsInsideReactNativeApp: PropTypes.func.isRequired,
    getJackpotsByGame: PropTypes.func.isRequired,
    jackpotsByGame: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    userBalance: {},
  };

  componentDidMount() {
    const {
      location: { search },
      setIsInsideReactNativeApp,
    } = this.props;

    const queryParams = parseQueryString(search, { ignoreQueryPrefix: true });

    const isInsideReactNativeApp = checkIsInsideReactNativeApp();

    setIsInsideReactNativeApp(isInsideReactNativeApp);

    if (isInsideReactNativeApp && Object.keys(queryParams).includes('payment_id')) {
      pushMessageToReactNativeApp({ event: 'closePaymentMobileScreen' });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isUserLoggedIn,
      openModal,
      userData,
      intl,
      userBalance: {
        withdrawableBalance
      },
      getJackpotsByGame,
      jackpotsByGame: { items, isInProgress },
    } = this.props;

    const { isUserLoggedIn: isUserLoggedInPrev } = prevProps;
    const gaSuccesfulSignUp = getLocalStorageData('gaSuccesfulSignUp');

    if (userData.currency && !items && !isInProgress) {
      getJackpotsByGame({ currency: userData.currency });
    }

    if (!isUserLoggedInPrev && isUserLoggedIn && getLocalStorageData('signUpCompleted')) {
      openModal(MODAL_IDS.PROMO_INFO);
      clearLocalStorageData('signUpCompleted');
    }

    const userVipStatus = userData?.extraData?.loyaltyPointsVIPLevel;
    const userKycStatus = userData?.kycStatus;

    if (!isUserLoggedInPrev && isUserLoggedIn && userVipStatus && isEmptyOrNil(getLocalStorageData('vipKYCshown'))) {
      if (LOYALTY_POINTS_STATUSES.BRONZE !== userVipStatus
        && KYC_STATUS.PASS !== userKycStatus) {
        openModal(MODAL_IDS.KYC_VERIFICATION_NOTIFICATION);
        setLocalStorageData('vipKYCshown', true);
      }
    }

    if (isUserLoggedIn && isUserLoggedIn !== isUserLoggedInPrev) {
      const lowBalanceNotificationData = parseJson(
        intl.formatMessage({ id: 'low-balance-notification.data' }),
        'error while parsing low balance data translation JSON'
      );

      if (
        lowBalanceNotificationData.isActive
        && !R.isNil(withdrawableBalance)
        && withdrawableBalance < lowBalanceNotificationData.minBalance
      ) {
        this.showLowBalanceNotification(lowBalanceNotificationData);
      }

      if (userData.partyId && gaSuccesfulSignUp) {
        GTM.event({ event: 'signup_success', attributes: { [USER_ID]: userData.partyId } });
        clearLocalStorageData('gaSuccesfulSignUp');
      }

      if (userData.partyId) {
        GTM.event({ event: 'login', attributes: { [USER_ID]: userData.partyId } });
      }
    }
  }

  showLowBalanceNotification = (data) => {
    const { showNotifications } = this.props;

    showNotifications({
      type: NOTIFICATION_TYPES.ERROR,
      duration: 60000,
      message: {
        title: data.messageTitleIntlKey,
        text: data.messageTextIntlKey,
      },
      data: {
        hasTitle: true,
        btnIntlKey: data.buttonTitleIntlKey,
        link: data.buttonLink,
      }
    });
  }

  render() {
    const {
      renderRoutes,
      route: { routes },
      is404,
      history,
      locale,
      deviceType,
      isPwa,
      isUserLoggedIn,
      userData,
      isUserRegistered,
      isInsideReactNativeApp
    } = this.props;

    const [routeInfo] = matchRoutes(routes, history.location.pathname);
    const currentRoute = R.propOr({}, 'route', routeInfo);

    const {
      page: pageName,
      noSidebar: hideSidebar,
      noHeader: hideHeader,
      noChat: hideChat,
      noFooter,
      hasNavigation,
      mobileBackRoute,
    } = currentRoute;

    // const { isDomainBarOpen } = this.context;

    const routeParams = R.pathOr({}, ['match', 'params'], routeInfo);
    const isLoyaltyPage = pageName === LOYALTY;
    const isGamePage = pageName === GAME;
    const isGamesPage = pageName === GAMES;
    const isHomePage = pageName === HOME;
    const isTournamentPage = pageName === TOURNAMENT;
    const isTournamentsPage = pageName === TOURNAMENTS;
    const isResetPasswordPage = pageName === RESET_PASSWORD;
    const isWalletPage = pageName === WALLET;
    const isProviderPage = pageName === PROVIDER;
    const isCategoryPage = pageName === CATEGORY;
    const isPaymentsPage = pageName === PAYMENTS;
    const isSportsbookPage = [SPORTSBOOK, IN_PLAY].includes(pageName);
    const isFaqPage = pageName === FAQ;
    const isBankingHistoryPage = pageName === BANKING_HISTORY;
    const isCashierPage = pageName === CASHIER;
    const isSignUpLandPage = pageName === LANDING_PAGE_ROUTE;

    // const liveChatGroup = LIVECHAT_INC_GROUPS[locale];

    const isMobile = deviceType === DEVICES_TYPES.MOBILE;
    const isGamePageOnMobile = isMobile && isGamePage;

    const isInProfile = R.includes(PROFILE, history.location.pathname);

    if (is404) {
      return (<PageNotFound />);
    }

    return (
      <>
        <GlobalNotificationsStack />

        {(!hideSidebar && !isGamePageOnMobile) && <Sidebar pageName={pageName} mobileBackRoute={mobileBackRoute} />}

        {/* Hide PWA INSTALL */}
        {/* <SkipServerRender skip> */}
        {/*  {!isPwa */}
        {/*    && !isInsideReactNativeApp */}
        {/*    && !isSportsbookPage */}
        {/*    && !isGamePage */}
        {/*    && isUserLoggedIn */}
        {/*    && !isResetPasswordPage && ( */}
        {/*      <PwaInstallGuide /> */}
        {/*  )} */}
        {/* </SkipServerRender> */}

        {/* for correct opening mobile game in iframe by direct link */}
        {/* <MobileGameContainer /> should mount before <Game /> */}
        {(isPwa || isMobile) && (
          <MobileGameContainer
            isMobile={isMobile}
            isPwa={isPwa}
            isGamePage={isGamePage}
          />
        )}

        {!hideHeader && (
          <Header
            pageName={pageName}
            mobileBackRoute={mobileBackRoute}
            params={routeParams.category}
            isUserLoggedIn={isUserLoggedIn}
            className={classNames({
              'main-content-overlap bg-blue-dark': [HOME, GAMES, GAME].includes(pageName),
            })}
          />
        )}

        <div>

          <SkipServerRender skip>
            {!isGamePage && !isInProfile && !isSportsbookPage && <GameWinnerCarouselMobile locale={locale} />}
          </SkipServerRender>

          <Container
            fluid
            className={classNames('d-flex flex-column main-container position-relative flex-grow-1 mt-1_25 mt-md-2_5', {
              'is-main-page': !isLoyaltyPage && !isGamePage && !isHomePage && !isTournamentPage && !isGamesPage,
              'px-sm-2_5 px-lg-5': !isLoyaltyPage && !isGamePage && !isTournamentPage && !isHomePage && !isGamesPage && !isWalletPage && !isBankingHistoryPage && !isSportsbookPage,
              'px-0': isLoyaltyPage || isGamePage || isTournamentPage || isHomePage || isWalletPage || isGamesPage || isSportsbookPage,
              'hide-sidebar': hideSidebar || isGamePageOnMobile,
              'has-navigation': hasNavigation && noFooter,
              // 'has-content-under-header': isHomePage || isTournamentPage || isGamesPage,
              'px-sm-5': isWalletPage || isBankingHistoryPage,
            })}
          >
            {isInProfile && (
              <div>
                <FormattedTag className="h3 d-block mt-2_5 mt-sm-4 mt-lg-4_5 mb-0_75 text-uppercase" id="profile.my-account" />

                <ProfileTabs pageName={pageName} />
              </div>
            )}
            {renderRoutes(routes)}
          </Container>
        </div>

        {!noFooter && (
          <Footer className={classNames('px-2 px-sm-2_5 px-lg-5', {
            'mt-6': !isLoyaltyPage && !isTournamentsPage && !isGamesPage && !isCategoryPage && !isProviderPage && !isPaymentsPage && !isGamePage && !isFaqPage,
            'has-navigation': hasNavigation,
            'is-logged-out': !isUserLoggedIn,
            'pt-3_5': isLoyaltyPage,
            // 'px-2 px-sm-2_5 px-lg-5': !isResetPasswordPage,
            // 'reset-password-page-footer mx-auto px-2_5 d-none d-sm-block': isResetPasswordPage,
            'hide-sidebar': hideSidebar,
            'mt-5': isProviderPage || isCategoryPage || isPaymentsPage,
          })}
          />
        )}

        <SkipServerRender skip>
          <SocialAuthTracker />
          <SignInModal />
          <SignUpModal />
          <PromoInfoModal />
          <SupportModal />

          <SideModalBar
            pageName={pageName}
            params={routeParams.category}
            isSportsbookPage={isSportsbookPage}
          />

          <CompleteSignUpModal />
          <ResetPasswordModal />
          <LinkErrorModal />
          <DepositModal isModalCentered />
          <NewLevelModal />
          <FreeSpinsNotifier />
          <ConfirmPhoneModal />

          <ConfirmOtpModal />
          <SetupOtpModal />
          <RemoveOtpModal />
          <KycNotificationModal />

          <BottomMobileActions
            isUserLoggedIn={isUserLoggedIn}
            isSportsbookPage={isSportsbookPage}
            isCashierPage={isCashierPage}
            isSignUpLandPage={isSignUpLandPage}
            isResetPasswordPage={isResetPasswordPage}
            hasNavigation={hasNavigation}
            pageName={pageName}
            category={routeParams.category}
          />

          {!hideChat && (
            <>
              <ZendeskChat
                isButtonHidden={isSportsbookPage}
                licenseKey="3f94f362-e4f4-4913-82b1-d9e37a07de56"
              />
            </>
          )}

          <RecaptchaLoader />

          <LocationTracker pageName={pageName} />

          {!isPwa
            && !isInsideReactNativeApp
            && <CookiesPolicy hasNavigation={hasNavigation} noSidebar={hideSidebar} />}

          <PaymentContainer />

          <CioTracker
            userData={userData}
            isUserLoggedIn={isUserLoggedIn}
            isUserRegistered={isUserRegistered}
          />
        </SkipServerRender>

        <GlobalEventsHandler pageName={pageName} />
        <IconsDefs />
      </>
    );
  }
}

export const App = withCoreComponent(
  AppCore,
  withLocale(
    injectIntl(
      withNotificationsActions(
        withPwaInstall(
          withUser(
            withModalActions(
              withRouter(
                withIsInsideReactNativeApp(
                  withJackpotsActions(
                    withJackpotsData(AppUI)
                  )
                )
              )
            ),
          ),
        ),
      )
    )
  ),
);
